.header-colour {
    background: #2C3E50;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4CA1AF, #2C3E50);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4CA1AF, #2C3E50); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.landing-grid {
    background: #2C3E50;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4CA1AF, #2C3E50);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4CA1AF, #2C3E50); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}
.profile-img {
    height: 400px;
    padding-top: 5em;
    padding-bottom: 1em;

}
.banner-text {
    background-color: black;
    opacity: .8;
    width: 50%;
    margin: auto;
    border-radius: 20px;
}

.banner-text h1 {
    font-size: 66px;
    font-weight: bold;
    color: white;
}

.banner-text hr {
    border-top: 5px dotted white;
    width: 70%;
    margin: auto;

}

.banner-text p {
    font-size: 20px;
    color: white;
    padding: 2em;
}

.social-links {
    display: flex;
    justify-content: space-between;
    width: 25%;
    margin: auto;
    padding-bottom: 50px;
}

.social-links i {
    color: white;
    font-size: 5em;
    padding: 20px;
    
}

.project-grid{
    display: flex;
    
}

.resume-col{
    background: #2C3E50 ;
    color: white;
    
}

.title-link{
    color: white;
    text-decoration: none;
}

.webName{
    color: black;
    text-decoration: none;
}

